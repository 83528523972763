import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { createScaffolderFieldExtension } from '@backstage/plugin-scaffolder-react';
import {
  LinkValidator,
  LinkValidatorSchema,
} from './LinkValidator/LinkValidator';

export const ValidateLinkExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'LinkValidator',
    component: LinkValidator,
    schema: LinkValidatorSchema,
  }),
);
