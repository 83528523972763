import { BackstageOverrides } from '@backstage/core-components';
import { BackstageOverrides as CatalogReactOverrides } from '@backstage/plugin-catalog-react';
import { BackstageTheme, createTheme, lightTheme } from '@backstage/theme';

import { AutocompleteClassKey } from '@material-ui/lab/Autocomplete';
import { AlertClassKey } from '@material-ui/lab/Alert';


// Labs types not included in overrides; https://github.com/mui/material-ui/issues/19427
declare module '@material-ui/core/styles/overrides' {
  export interface ComponentNameToClassKey {
    MuiAlert: AlertClassKey;
    MuiAutocomplete: AutocompleteClassKey;
  }
}

const baseTheme = createTheme({
  palette: {
    ...lightTheme.palette,
    primary: {
      main: '#0f62fe',
      light: '#0f62fe',
      dark: '#0f62fe',
    },
    secondary: {
      main: '#FF5630',
      light: '#FFAB00',
      dark: '#6554C0',
    },
    grey: {
      50: '#ffffff',
      100: '#f4f4f4',
      200: '#fff',
      300: '#c6c6c6',
      400: '#a8a8a8',
      500: '#8d8d8d',
      600: '#6f6f6f',
      700: '#525252',
      800: '#393939',
      900: '#262626',
    },
    error: {
      main: '#da1e28',
      light: '#da1e28',
      dark: '#ff8389',
    },
    warning: {
      main: '#f1c21b',
      light: '#f1c21b',
      dark: '#f1c21b',
    },
    success: {
      main: '#24a148',
      light: '#24a148',
      dark: '#42be65',
    },
    info: {
      main: '#0f62fe',
      light: '#0043ce',
      dark: '#4589ff',
    },
    navigation: {
      ...lightTheme.palette.navigation,
      background: '#161616',
      color: '#f4f4f4',
      indicator: '#0f62fe',
      navItem: {
        hoverBackground: '#393939',
      },
    },
    text: {
      primary: '#161616',
      secondary: "#525252",
    },
    background: {
      default: '#f4f4f4',
    },
  },
  fontFamily: 'IBM Plex Sans, Helvetica Neue, Arial, sans- serif',
  defaultPageTheme: 'home',
});

const createCustomThemeOverrides = (
  theme: BackstageTheme,
): BackstageOverrides & CatalogReactOverrides => {
  return {
    BackstageHeader: {
      header: {
        backgroundImage: 'unset',
        boxShadow: 'unset',
        paddingBottom: theme.spacing(1),
        backgroundColor: "#161616",
        borderBottom: "1px solid",
      },
      title: {
        color: "#fff",
        fontWeight: 600,
        fontSize:"1.25rem",
        marginBottom: ".5rem"
      },
      subtitle: {
        color: "#f4f4f4",
        fontSize: "1rem",
        fontWeight: "normal",
        opacity: "1",
      },
      type: {
        color: "#78a9ff",
        fontSize: ".75rem",
        fontWeight: "normal",
        opacity: "1",
      },
    },
    BackstageHeaderTabs: {
      defaultTab: {
        color: "#525252",
        fontSize: 'inherit',
        textTransform: 'none'
      },
    },
    BackstageClosedDropdown: {
      icon: {
        '& path': {
          fill: theme.palette.grey[800],
        },
      },
    },
    BackstageOpenedDropdown: {
      icon: {
        '& path': {
          fill: theme.palette.grey[800],
        },
      },
    },
    BackstageTable: {
      root: {
        '&> :first-child': {
          borderBottom: `1px solid #e0e0e0`,
          boxShadow: 'none',
          backgroundColor: "#fff",
        },
        '& th': {
          borderTop: 'none',
          textTransform: 'none !important',
        },
        '& tr': {
          backgroundColor: "#fff !important",
          borderBottom: `1px solid #e0e0e0`,
        }
      },
    },
    BackstageTableHeader: {
      header: {
        backgroundColor: "#e0e0e0",
        color: theme.palette.text.primary,
        paddingTop: '16px',
        paddingBottom: '16px'
      }
    },
    CatalogReactUserListPicker: {
      title: {
        textTransform: 'none',
      },
    },
    MuiAlert: {
      root: {
        borderRadius: 0,
      },
      standardError: {
        color: '#FFFFFF',
        backgroundColor: theme.palette.error.dark,
        '& $icon': {
          color: '#FFFFFF',
        },
      },
      standardInfo: {
        color: '#FFFFFF',
        backgroundColor: theme.palette.primary.dark,
        '& $icon': {
          color: '#FFFFFF',
        },
      },
      standardSuccess: {
        color: '#FFFFFF',
        backgroundColor: theme.palette.success.dark,
        '& $icon': {
          color: '#FFFFFF',
        },
      },
      standardWarning: {
        color: theme.palette.grey[700],
        backgroundColor: theme.palette.secondary.light,
        '& $icon': {
          color: theme.palette.grey[700],
        },
      },
    },
    MuiAutocomplete: {
      root: {
        '&[aria-expanded=true]': {
          backgroundColor: theme.palette.grey[800],
          color: '#FFFFFF',
        },
        '&[aria-expanded=true] path': {
          fill: '#FFFFFF',
        },
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: theme.palette.grey[50],
      },
    },
    MuiButton: {
      root: {
        borderRadius: 3,
        textTransform: 'none',
      },
      contained: {
        boxShadow: 'none',
      },
    },
    MuiChip: {
      root: {
        borderRadius: 3,
        backgroundColor: theme.palette.grey[50],
        color: theme.palette.text.primary,
        margin: 4,
      },
    },
    MuiFormLabel: {
      root: {
        color: "#525252"
      }
    },
    MuiDialogContentText: {
      root: {
        color: theme.palette.text.primary,
      }
    },
    MuiSelect: {
      root: {
        '&[aria-expanded]': {
          backgroundColor: theme.palette.grey[50],
          color: theme.palette.text.primary,
        },
      },
    },
    MuiCheckbox: {
      root: {
        backgroundColor: "transparent",
        color: theme.palette.text.primary,
        borderRadius: "2px"
      },
      colorSecondary: {
        color: "#161616 !important"
      }
    },
    MuiSwitch: {
      root: {
        padding: 10,
      },
      switchBase: {
        padding: 12,
      },
      thumb: {
        backgroundColor: '#FFFFFF',
        height: 14,
        width: 14,
      },
      track: {
        borderRadius: 9,
      },
    },
    MuiTabs: {
      indicator: {
        transition: 'none',
      },
      root: {
        "& .MuiTab-wrapper": {
          color: "#f2f4f8"
        }
      }
    },
    MuiTypography: {
      button: {
        textTransform: 'none',
      },
    },
    MuiListSubheader: {
      root: {
        color: theme.palette.text.primary,
      }
    }
  };
};

export const IBMCarbon: BackstageTheme = {
  ...baseTheme,
  overrides: {
    ...baseTheme.overrides,
    ...createCustomThemeOverrides(baseTheme),
  },
};