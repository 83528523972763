import React, {useState, useEffect} from 'react';
import FormControl from '@material-ui/core/FormControl';
import { LinearProgress, TextField } from '@material-ui/core';
import { z } from 'zod';
import { makeFieldSchemaFromZod} from '@backstage/plugin-scaffolder';
import { CustomFieldExtensionSchema } from '@backstage/plugin-scaffolder-react';
import { useDebounce } from 'use-debounce';

function isValidUrl(url: string): boolean {
  try {
    const _ = new URL(url);
    return true;
  } catch (error) {
    return false;
  }
}

const linkFieldSchema = makeFieldSchemaFromZod(z.string());

export const LinkValidatorSchema: CustomFieldExtensionSchema = linkFieldSchema.schema;
type LinkValidatorProps = typeof linkFieldSchema.type;

export const LinkValidator: (props: LinkValidatorProps) => React.JSX.Element = (
  props
) => {
  const {
    onChange,
    required,
    schema: { title = 'URL' },
    rawErrors,
    formData,
    uiSchema: { 'ui:autofocus': autoFocus },
    idSchema,
    placeholder,
  } = props;

  const [text, setText] = useState('');
  const [debouncedText] = useDebounce(text, 1000);
  const [isValid, setIsValid] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [description, setDescription] = useState('A valid URL');

  useEffect(() => {
    if (debouncedText) {
      setIsFetching(true);
      if (!isValidUrl(debouncedText)) {
        setIsFetching(false);
        setIsValid(false);
        setDescription(`❌ URL is not valid`);
        return;
      }
      fetch(debouncedText, { method: 'HEAD' }).then((response) => {
        if (!response.ok) {
          setIsFetching(false);
          setIsValid(false);
          setDescription(`❌ URL is not valid. HTTP ${response.status} ${response.statusText}`);
        } else {
          setIsFetching(false);
          setIsValid(true);
          setDescription('✅ URL is valid');
        }        
      }).catch((error) => {
        setIsFetching(false);
        setIsValid(false);
        setDescription(`❌ URL is not valid. ${error}`)
      })
    }
  }, [debouncedText]);

  return (
    <FormControl
      margin="normal"
      required={required}
      error={rawErrors?.length > 0 && !formData}
    >
      {isFetching ? <LinearProgress/> : null}
      <TextField
        id={idSchema?.$id}
        label={title}
        placeholder={placeholder}
        helperText={description}
        required={required}
        value={formData ?? ''}
        onChange={e => {
          setText(e.target?.value || '');
          onChange(e.target?.value);
        }}
        margin="normal"
        error={!isValid}
        inputProps={{ autoFocus }}
      />
    </FormControl>
  );
};
