import { createPlugin, createRoutableExtension } from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const telemetryFrontendPlugin = createPlugin({
  id: 'telemetry-frontend',
  routes: {
    root: rootRouteRef,
  },
});

export const TelemetryFrontendPage = telemetryFrontendPlugin.provide(
  createRoutableExtension({
    name: 'TelemetryFrontendPage',
    component: () =>
      import('./components/ImpactComponent').then(m => m.ImpactComponent),
      // import('./components/ExampleComponent').then(m => m.ExampleComponent),
    mountPoint: rootRouteRef,
  }),
);
