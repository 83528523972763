import {ScmAuth, ScmIntegrationsApi, scmIntegrationsApiRef,} from '@backstage/integration-react';
import {
    AnyApiFactory, ApiRef, BackstageIdentityApi,
    configApiRef,
    createApiFactory, 
    createApiRef,
    identityApiRef,
    discoveryApiRef,
    oauthRequestApiRef, OpenIdConnectApi, ProfileInfoApi, SessionApi,
} from '@backstage/core-plugin-api';
import {OAuth2} from '@backstage/core-app-api';

export const bluepagesOIDCAuthApiRef: ApiRef<
    OpenIdConnectApi & ProfileInfoApi & BackstageIdentityApi & SessionApi
> = createApiRef({
    id: 'auth.bluepages',
});

export const apis: AnyApiFactory[] = [
    createApiFactory({
        api: scmIntegrationsApiRef,
        deps: {configApi: configApiRef},
        factory: ({configApi}) => ScmIntegrationsApi.fromConfig(configApi),
    }),
    ScmAuth.createDefaultApiFactory(),
    createApiFactory({
        api: bluepagesOIDCAuthApiRef,
        deps: {
            discoveryApi: discoveryApiRef,
            oauthRequestApi: oauthRequestApiRef,
            configApi: configApiRef,
            identityApi: identityApiRef
        },
        factory: ({discoveryApi, oauthRequestApi, configApi}) =>
            OAuth2.create({
                discoveryApi,
                oauthRequestApi,
                provider: {
                    id: 'bluepages',
                    title: 'Using IBM OIDC bluepages',
                    icon: () => null, // TODO: https://login.w3.ibm.com/favicon.ico
                },
                environment: configApi.getString('auth.environment'),
                defaultScopes: [
                    'openid',
                    'profile',
                    'email',
                ],
            }),
    }),
];